<template lang="pug">
div
  slot(:reset="resetRequests")
  .reservation-item(
    v-for="(reservationRequest, index) in requests"
    :key="index"
  )
    reservation(
      v-if="isReservationVisible(index)"
      :item="reservationRequest"
      :index="index"
      :formErrors="formErrors"
      :getLocations="getExamLocation"
      :getCourseLocation="getCourseLocation"
      :dragstart="dragstart"
      :disabled="disabled"
      :license-types="model.licenseType ? [model.licenseType] : licenseTypes"
      @deleteBtnClicked="remove(index)"
      @dragstart="dragItem($event, {item: reservationRequest, dragFrom: index})"
      @dragend="onDragEnd"
      :draggable="!hidePartOfReservations"
      @startEditing="editingHandler"
      @drop="drop($event, {dragTo: index})"
      :avaliableSlots="avaliableSlots"
      :onHoldShowing="onHoldShowing"
      :showCommentFields="showCommentFields"
      :dontMarkManually="dontMarkManually"
      ref="request"
    )
      template(v-slot:form="form")
        slot(name="form" :item="reservationRequest")
    reservation-show-more-btn(
      v-if="isReservationMoreBtnVisible(index)"
      @click.native="showAllReservations"
      :hiddenItemsCount="hiddenReservationsCount"
      :avaliableSlots="avaliableSlots"
      :onHoldShowing="onHoldShowing"
    )
  .d-flex.justify-center.pt-6
    v-btn(@click="add" outlined color="primary" :disabled="disabled").btn-common.btn-common_bold.mr-2
      v-icon(small) mdi-plus
      | {{ addText }}
    bulk-add(
      @save="addMultipleRequests"
      :disabled="disabled"
      :licenseTypes="model.licenseType ? [model.licenseType] : licenseTypes"
    )
</template>

<script>
import { convertToDefaultDateFormat } from '@/util'

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    formErrors: Object,
    getExamLocation: Function,
    getCourseLocation: Function,
    avaliableSlots: {
      default: true,
      type: Boolean
    },
    onHoldShowing: {
      default: true,
      type: Boolean
    },
    showCommentFields: {
      default: true,
      type: Boolean
    },
    dontMarkManually: Boolean,
    addText: {
      type: String,
      default: 'Add Reservation'
    },
    disabled: Boolean,
    licenseTypes: Array,
  },

  computed: {
    hidePartOfReservations() {
      return !this.showMoreClicked && this.requests.length > this.maxRequestsDontHide;
    },
    hiddenReservationsCount() {
      return this.requests.length - 2;
    },
  },

  data: () => ({
    requests: [],
    dragFrom: null,
    dragstart: false,
    showMoreClicked: false,
    maxRequestsDontHide: 3,
  }),

  created() {
    this.setRequests()
  },

  methods: {
    isReservationVisible(index) {
      if (this.hidePartOfReservations && index !== 0 && index < this.requests.length - 1) return false;
      return true;
    },
    isReservationMoreBtnVisible(index) {
      return this.hidePartOfReservations && index === 1;
    },
    showAllReservations() {
      this.showMoreClicked = true;
    },
    setRequests() {
      this.requests = this.model.filteredRequests
      this.addRequestIfNoAvailable()
    },
    addRequestIfNoAvailable() {
      if(!this.requests.length) {
        if (this.model.filteredRequests && !this.model.filteredRequests.length) {
          this.model.addReservationRequest()
        }
        this.requests = this.model.filteredRequests
      }
      this.updateCounts()
    },
    // Todo Need refactor (after candidate updated, data for reservations didn't update, but some of data must be updated)
    updateCounts() {
      this.requests.forEach((request, index) => {
        const _request = this.model.requests.find(req => req.course.ID === request.course.ID &&
          req.examDate === request.examDate &&
          req.location === request.location &&
          req.timeFrom === request.timeFrom &&
          req.timeTo === request.timeTo
        )
        if (_request) {
          this.requests[index].slotsCount = _request.slotsCount
        }
      })
    },
    resetRequests() {
      this.requests = []
      this.model.addReservationTo(this.requests)
    },

    remove(index) {
      this.requests.splice(index, 1)
    },

    dragItem(event, {item, dragFrom}) {
      this.dragstart = true
      const transferedData = JSON.stringify(item)
      event.dataTransfer.setData("item", transferedData);
      this.dragFrom = dragFrom
    },
    editingHandler() {
      this.model.editing = true
      this.$emit('changed')
    },

    drop(event, {dragTo}) {
      event.preventDefault();
      this.dragstart = false;
      this.isDragOver = false;
      this.requests.splice(dragTo, 0, this.requests.splice(this.dragFrom, 1)[0]);
      this.editingHandler()
    },

    onDragEnd() {
      this.dragstart = false
    },

    add() {
      this.model.addReservationTo(this.requests)
    },

    async addMultipleRequests(data) {
      let _requests = []
      data.forEach((item, index) => {
        this.model.addReservationTo(_requests)
        _requests[index].exam = item.exam
        _requests[index].course = item.course

        let examDate = convertToDefaultDateFormat(item.exam.date)
        if (item.exam.dateFrom && item.exam.dateTo) examDate = convertToDefaultDateFormat(item.exam.dateFrom)
        _requests[index].examDate = examDate

        _requests[index].location = item.exam.location
        _requests[index].timeFrom = item.exam.timeFrom
        _requests[index].timeTo = item.exam.timeTo
      })
      if (!this.requests.length) this.requests = _requests
      else if (this.requests[0].course.ID) this.requests = [...this.requests, ..._requests]
      else {
        this.requests.splice(0, 1)
        await this.$nextTick()
        this.requests = [...this.requests, ..._requests]
      }
    },
  },

  components:{
    reservation: () => import('../CandidateItemReservation.vue'),
    bulkAdd: () => import('../reservation/CandidateBulkReservation.vue'),
    reservationShowMoreBtn: () => import('../reservation/CandidateReservationShowMoreBtn.vue')
  }
}
</script>
<style lang="scss" scoped>
.reservation-item::v-deep {
  &:first-child {
    .reservation {
      margin-top: 0;
    }
  }
}

</style>
